import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';

const DEFAULT_STATE = {
  isCineEnabled: true,
  cines: {
    /*
     * 1: { isPlaying: false, frameRate: 24 };
     */
  },
  previousCines: {
    /*
     * 1: { isPlaying: false, frameRate: 24 };
     */
  },
};

const DEFAULT_CINE = { isPlaying: false, frameRate: 24 };

export const CineContext = createContext(DEFAULT_STATE);

function changeCineProperty(cines, id, frameRate, isPlaying) {
  if (!cines[id]) {
    cines[id] = { id, ...DEFAULT_CINE };
  }

  cines[id].frameRate = frameRate || cines[id].frameRate;
  cines[id].isPlaying = typeof isPlaying === 'boolean' ? isPlaying : cines[id].isPlaying;
}

export default function CineProvider({ children, service }) {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_CINE': {
        const { ids = [], frameRate, isPlaying = undefined } = action.payload;
        const previousCines = state.cines;
        const cines = cloneDeep(state.cines);

        ids.forEach(id => changeCineProperty(cines, id, frameRate, isPlaying));

        return { ...state, cines, previousCines };
      }
      case 'SET_IS_CINE_ENABLED': {
        return { ...state, ...{ isCineEnabled: action.payload } };
      }
      default:
        return action.payload;
    }
  };

  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  const getState = useCallback(() => state, [state]);

  const setIsCineEnabled = useCallback((isCineEnabled: boolean) => {
    dispatch({ type: 'SET_IS_CINE_ENABLED', payload: isCineEnabled });
  }, [dispatch]);

  const setCine = useCallback(
    ({ id, frameRate, isPlaying }) =>
      dispatch({
        type: 'SET_CINE',
        payload: {
          ids: [id],
          frameRate,
          isPlaying,
        },
      }),
    [dispatch]
  );

  const setCineAll = useCallback(
    ({ ids, frameRate, isPlaying }) =>
      dispatch({
        type: 'SET_CINE',
        payload: {
          ids,
          frameRate,
          isPlaying,
        },
      }),
    [dispatch]
  );

  /**
   * Sets the implementation of a modal service that can be used by extensions.
   *
   * @returns void
   */
  useEffect(() => {
    if (service) {
      service.setServiceImplementation({ getState, setIsCineEnabled, setCine });
    }
  }, [getState, service, setCine, setCineAll, setIsCineEnabled]);

  const api = {
    getState,
    setCine,
    setCineAll,
    setIsCineEnabled: (isCineEnabled: boolean) => service.setIsCineEnabled(isCineEnabled),
    playClip: (element, playClipOptions) => service.playClip(element, playClipOptions),
    stopClip: (element, stopClipOptions) => service.stopClip(element, stopClipOptions),
  };

  return (
    <CineContext.Provider value={[state, api]}>{children}</CineContext.Provider>
  );
}

CineProvider.propTypes = {
  children: PropTypes.any,
  service: PropTypes.shape({
    setServiceImplementation: PropTypes.func,
  }).isRequired,
};

export const useCine = () => useContext(CineContext);
